<template>
  <div class="uk-grid uk-flex uk-flex-center in-contact-6">
    <div class="uk-width-3-5@m">
      <div
        class="uk-grid uk-child-width-1-2@m uk-margin-medium-top uk-text-center"
        data-uk-grid=""
      >
        <div>
          <h5 class="uk-margin-remove-bottom">
            <i class="fa fa-envelope fa-sm uk-margin-small-right"></i>Email
          </h5>
          <p class="uk-margin-small-top uk-margin-remove-bottom">
            info@myforexglobal.com
          </p>
          <p class="uk-text-small uk-text-muted uk-text-uppercase">
            for public inquiries
          </p>
        </div>
        <div>
          <h5 class="uk-margin-remove-bottom">
            <i class="fa fa-phone fa-sm uk-margin-small-right"></i>Call
          </h5>
          <p class="uk-margin-small-top uk-margin-remove-bottom">
            (254) 728747772
          </p>
          <p class="uk-text-small uk-text-muted uk-text-uppercase">
            Mon - Fri, 9am - 5pm
          </p>
        </div>
      </div>
      <hr class="uk-margin-medium" />
      <p
        class="
          uk-margin-remove-bottom uk-text-lead uk-text-muted uk-text-center
        "
      >
        Have a questions?
      </p>
      <h1 class="uk-margin-small-top uk-text-center">
        Let's <span class="in-highlight">get in touch</span>
      </h1>
      <form
        id="contact-form"
        class="uk-form uk-grid-small uk-margin-medium-top uk-grid"
        @submit.prevent="submit"
      >
        <div class="uk-width-1-2@s uk-inline uk-first-column">
          <span class="uk-form-icon fas fa-user fa-sm"></span>
          <input
            class="uk-input uk-border-rounded"
            id="name"
            name="name"
            type="text"
            placeholder="Full name"
            v-model="content.name"
            required
          />
        </div>
        <div class="uk-width-1-2@s uk-inline">
          <span class="uk-form-icon fas fa-envelope fa-sm"></span>
          <input
            class="uk-input uk-border-rounded"
            id="email"
            name="email"
            type="email"
            placeholder="Email address"
            v-model="content.email"
            required
          />
        </div>
        <div class="uk-width-1-1 uk-inline uk-grid-margin uk-first-column">
          <span class="uk-form-icon fas fa-pencil fa-sm"></span>
          <input
            class="uk-input uk-border-rounded"
            id="subject"
            name="subject"
            type="text"
            placeholder="Subject"
            v-model="content.subject"
            required
          />
        </div>
        <div class="uk-width-1-1 uk-grid-margin uk-first-column">
          <textarea
            class="uk-textarea uk-border-rounded"
            id="message"
            name="message"
            rows="6"
            placeholder="Message"
            v-model="content.message"
            required
          ></textarea>
        </div>
        <button type="submit" id="submit-contact-form" style="display: none"></button>
        <div class="uk-width-1-1 uk-grid-margin uk-first-column">
          <vue-recaptcha sitekey="6LeB-BoeAAAAAB0aabXtKoFuvhWWQ4pLUMoCMzsR" @verify="submitForm">
            <button
              class="uk-width-1-1 uk-button uk-button-primary uk-border-rounded"
              id="sendemail"
              type="submit"
              name="submit"
            >
              Send Message
            </button>
          </vue-recaptcha>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha
  },

  data() {
    return {
      content: {}
    }
  },

  methods: {
    submitForm(response) {
      this.content.recapture_token = response
      document.getElementById('submit-contact-form').click()
    },

    submit() {
      this.$axios.post('/api/v1/contact-us', this.content).then(() => {
        this.content = {}
        this.$toasted.show('Your query has been sent. We will contact you shortly.', { duration: 5000 })
      }).catch(() => {
        this.$toasted.show('Your query could not be sent. please try again later')
      })
    }
  }
};
</script>